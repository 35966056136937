* {
  scroll-behavior: smooth;
  font-family: "Times New Roman", Times, serif;
}

.btn {
  padding: 5px;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.749);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.video-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;

  z-index: -1;

  opacity: 1;
}

.NavigatioinMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .5px solid rgb(57, 57, 57);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.256);
  background-color: #000000df;
  color: white;
  /* background-image: url('./Images/Wallpaper4.jpg'); */
  position: sticky;
  z-index: 1000;
  top: 0;
}

.NavigatioinMenu .Logo .logo-title {
  text-decoration: none;
  color: white;
}

.NavigatioinMenu .Logo .logo-title h3 {
  font-size: larger;
  margin-top: 7px;
}

.NavigatioinMenu .Logo .logo-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.NavigatioinMenu .Logo .logo-title img {
  height: 34px;
  width: 34px;
  border-radius: 50%;
  object-fit: cover;
}

i {
  margin-right: 3px;
  margin-left: 3px;
}

.Documents {
  /* background-image: url('./Images/Wallpaper4.jpg'); */
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 50px;
  height: 100vh;
}

.Documents .card-body iframe {
  width: 100%;
  height: 700px;
}

.Documents .dropdown-menu li {
  display: flex;
  padding: 5px;
  justify-content: space-between;
}

.Documents .dropdown .dropdown-toggle {
  font-size: large;
  font-weight: bold;
}

.NewNoticebtn {
  display: flex;
  justify-content: center;
  align-items: center;

}

.NewNoticebtn span {
  padding-right: 2px;
  animation: .5s blink infinite;
  font-weight: bold;
}

@keyframes blink {

  0% {
    color: red;
  }

  50% {
    color: blue;
  }

  100% {
    color: red;
  }
}

.calendar {
  background-color: rgb(255, 255, 255);
}

.calendar .modal-title {
  color: red;
  animation: blink 0.5s infinite alternate;
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.calendar iframe {
  width: 500px;
  height: 500px;
  border: 1px solid black;
  border-radius: 10px;
}

.calendar .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.calendar .modal-header span {
  font-size: larger;
  color: red;
  animation: .45s blink infinite;
}

.Menu {
  display: none;
}

.Notice {
  width: 100%;
  height: 600px;
}

.NoticeBoard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  width: 100%;
  height: 100%;
}

.SingleNotice {
  display: flex;
  flex-direction: column;
  gap: 5px;

  width: 100%;
  height: 100%;
}

.NoticeBoard iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.modal-backdrop {
  position: unset;
  z-index: 1 !important;
}

.Logo {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  gap: 5px;
  white-space: nospace;
}


.NavButtons {
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 60%;
}

.NavButtons .btn {
  transition: .3s ease;
  border: 1px solid rgba(255, 255, 255, 0.548);
  color: white;
}

.NavButtons .btn:hover {
  color: black;
  background-color: white;
  transform: scale(1.1);
  transition: .3s ease;
}

.Authenticate {
  padding: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.Info {
  background-color: lightgoldenrodyellow;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 60px;
}

.Excel {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Data {
  display: flex;
  justify-content: center;
}

.Data table {
  text-align: center;
}

.Data th {
  border: 1px solid black;
  width: 500px;
}

.Data td {
  border: 1px dotted black;
}

.Actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Actions form {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Actions form input {
  width: 500px;
  height: 30px;
  border-radius: 10px;
}

.AddModal {
  display: flex;
  flex-direction: column;
}

.AddModal input {
  border-radius: 10px;
}

.AddModal textarea {
  border-radius: 10px;
  min-height: 100px;
  resize: vertical;
  overflow-y: visible;
}

.Gallery {
  /* background-color: rgb(211, 225, 181); */
  /* background-image: url('./Images/Wallpaper4.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: fit-content;
  margin-bottom: 50px;
}

.CarouselDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.CarouselDiv .carousel-item img {
  height: 600px;
  width: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.CarouselDiv .carousel {
  width: 1000px;
  border-radius: 50px;
  overflow: hidden;
}

.card img {
  width: 100%;
  height: 320px;
  object-fit: cover;
  border-radius: 30px;
}

.card {
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
  transition: 0.3s ease-in-out;
}

.card:hover {
  transform: scale(1.04);
  cursor: pointer;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.728);
  filter: brightness(1.2);
}

.AdditionalImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.AdditionalImages img {
  width: 500px;
  object-fit: contain;
}

.Foot {
  width: 100%;
  background-color: #000000df;
  color: white;
  position: fixed;
  bottom: 0;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  ;
  z-index: 1000;
}

.Foot footer {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Foot footer ul {
  display: flex;
  gap: 5px;
}

.Foot .btn {
  color: white;
  border: 0.5px solid white;
}

.Foot .btn:hover {
  color: black;
  background-color: white;
}

.Home {
  margin-bottom: 50px;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* background-image: url('./Images/Wallpaper5.jpg'); */
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: linear-gradient(vertical #f0ec9a red ); */
  /* background-color: #f0ec9a; */
  /* background-blend-mode: multiply;*/
  color: white;
  position: relative;
}

.Intro {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;

  height: fit-content;
  margin-bottom: 10vh;
  margin-top: 10vh;
}

.scaleUp {
  animation: scaleUp 0.75s ease-in-out forwards;
}

@keyframes scaleUp {
  from {
    opacity: 0;
    transform: scale(0.1);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.tagline-description .p-message {
  color: red;
  width: fit-content;
}

.Intro h1 {
  font-size: 5rem;
  padding: 8px;
  border-radius: 25px;
  width: fit-content;

  background: linear-gradient(45deg, rgb(17, 242, 17), rgb(255, 255, 83), rgb(255, 11, 11));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

}

.Intro .tagline {
  font-size: 1.5rem;
  background-color: #00000093;
  padding: 5px;
  border-radius: 25px;
  width: fit-content;
}

.tagline-description {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100%;
  text-align: center;
  padding: 20px;
}

.tagline-description h3 {
  font-size: 4rem;
  background: linear-gradient(45deg, rgb(17, 242, 17), rgb(255, 255, 83), rgb(255, 11, 11));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
  opacity: 0;
}

.tagline-description p {
  font-size: 1.5rem;
  text-align: center;
  color: black;
  padding: 5px;
  border-radius: 25px;
  width: 75%;
}

.Intro img {
  height: 300px;
  width: fit-content;
  border-radius: 10px;
  object-fit: contain;
}

.introaddress {
  font-weight: bold;
  font-size: 20px;
}

.About {
  width: 100%;
  padding: 30px;

  color: black;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.column {
  flex-direction: row;
}

.column-reverse {
  flex-direction: row-reverse;
}

.about-img {
  height: 380px;
  width: 100%;
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.589));
  border-radius: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.about-img img {
  height: 100%;
  border-radius: 30px;


  object-fit: cover;
}

.about-description {
  width: 100%;
}

.about-description p {
  text-align: justify;
  text-justify: inter-word;
  padding: 20px;
}

.About .CarouselImage {
  border: 1px solid black;
  padding: 5px;
  border-radius: 20px;
}

.CarouselImage img {
  height: 400px;
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.About p {
  font-size: 20px;
  font-weight: 500;
}

/* @media screen and (max-width: 1400px) {
  * {
    font-size: 13px;
  }

  .NavigatioinMenu {
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-between;
    gap: 0px;
    position: sticky;
    z-index: 1000;
    top: 0;
  }

  .Logo {
    padding: 5px;
  }

  .NavButtons {
    padding: 1px;
    gap: 5px;
    flex-wrap: wrap;
  }

  .Authenticate {
    padding: 1px;
    gap: 5px;
  }

  .Info {
    padding: 10px;
    gap: 10px;
    margin-bottom: 60px;
  }

  .Actions form input {
    width: 300px;
  }
} */

.logo-title h4 {
  display: none;
}

@media (max-width: 1200px) {
  .NavigatioinMenu {
    justify-content: space-between;
    align-items: center;
  }

  .NavButtons {
    padding: 1px;
    justify-content: center;
    gap: 2px;
  }

  .NavigatioinMenu .Logo .logo-title {
    gap: 1px;
  }

  .NavigatioinMenu .Logo .logo-title h3 {
    margin-top: 10px;
    font-size: large;
  }

  .NavigatioinMenu .Logo .logo-title img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
  }


  /* .Menu {
    display: block;
    position: relative;
    width: 100%;
    height: 30px;
    /* background-image: url('./Images/Wallpaper5.jpg');
    color: black;

  } */

  /* .menutab {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .menutab p {
    color: white;
  }

  .menutab p {
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
    text-align: center;
  }

  .Menu .Logo h3 {
    display: contents;
    color: #333;
  }

  .Menu .Logo h5 {
    display: none;
  }

  .Menu .dropdown:hover>.dropdown-menu {
    display: block;
  }

  .Menu .NavButtons {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .dropdown-menu .btn {
    width: fit-content;
    color: black;
  } */
}

.Intro h1 {
  white-space: nowrap;
}

.tagline-description h3 {
  white-space: nowrap;
}

@media (max-width: 1150px) {


  .about-img {
    height: 300px;
    width: 100%;
  }

  .About .about-description p {
    font-size: medium;
  }

}

@media (max-width: 1050px) {


  .calendar iframe {
    width: 400px;
    height: 400px;
  }

  .Gallery-Cards .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Always 3 columns */
    gap: 10px;
  }
}



@media (max-width: 1000px) {

  .NavButtons i {
    display: none;
  }

  .about-img {
    height: 250px;
    width: 100%;
  }

  .logo-title h4 {
    margin-top: 10px;
    margin-left: 2px;
    display: block;
  }

  .NavigatioinMenu .Logo .logo-title h3 {
    display: none;
  }

  .calendar .modal-body {
    flex-direction: column;
    gap: 10px;
  }

  .calendar .modal-body iframe {
    width: 100%;
  }

  .calendar iframe {
    width: 350px;
    height: 350px;
  }
}

@media (max-width: 850px) and (max-height: 1200px) {
  .App {
    background-size: cover;
  }
}

.Gallery-Cards .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Always 3 columns */
  gap: 10px;

}


@media (max-width: 850px) {

  .Gallery-Cards .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    /* Always 3 columns */
    gap: 10px;
  }

  .tagline-description {
    height: fit-content;
    margin-bottom: 10vh;
  }

  .about-img {
    height: 210px;
    width: 100%;
  }

  .About .about-description p {
    font-size: medium;
  }

  .logo-title h4 {
    display: none;
  }

  .Gallery {
    padding: 10px;
    gap: 20px;
    margin-bottom: 60px;
  }

  .CarouselDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }

  .CarouselDiv .carousel-item img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  .CarouselDiv .carousel {
    width: 700px;
  }

  .card img {
    width: 100%;
    height: 320px;
    object-fit: cover;
  }

  .AdditionalImages {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .AdditionalImages img {
    width: 500px;
    object-fit: contain;
  }



  .Intro h1 {
    white-space: nowrap;
    font-size: 3.5rem;
  }

  .Intro .tagline {
    font-size: large;
  }

  .tagline-description h3 {
    white-space: nowrap;
    font-size: 3rem;
  }

  .tagline-description p {
    font-size: medium;
  }
}


@media (max-width: 777px) {

  .App {
    background-size: cover;
  }

  .About {
    flex-direction: column;
    padding: 10px;
    gap: 5px;
  }

  .about-img {
    height: 320px;
  }

  .about-img img {
    width: 100%;

  }

  .about-description p {
    margin: 10px;
    padding: 10px;
  }

  .Menu {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #000000df;
  }

  .Menu .collapse .card {
    border-radius: 0px;
  }

  .Menu .collapse .card .NavButtons {
    flex-direction: column;
    align-items: flex-start;
  }

  .Menu .collapse .card .NavButtons .btn {
    text-align: start;
    width: 100%;
    background-color: white;
    color: black;
  }

  .NewNoticebtn {
    justify-content: flex-start;
  }

  .NavigatioinMenu {
    display: none;
  }

  .Menu .menutab {
    font-size: larger;
    height: 50px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .Menu .btn.menutab:hover {
    color: white;
  }

  .Excel button {
    font-size: 0px;
  }

  .CarouselDiv .carousel {
    width: fit-content;
  }
}

@media (max-width: 680px) {

  .tagline-description p {
    width: 100%;
  }

  .Gallery-Cards .row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    /* Always 3 columns */
    gap: 10px;
  }

  .Intro img {
    height: 250px;
  }
}

@media (max-width: 650px) {
  .Data tr td button {
    font-size: 0px;
  }

  i {
    padding: 2px;
  }


}

@media (max-width: 600px) {

  .Intro {
    justify-content: flex-start;
    margin: 15vh;
    height: max-content;
  }

  .tagline-description {
    height: fit-content;
  }

  .Actions form input {
    width: 200px;
  }

  .introaddress {
    font-size: 10px;
  }

  .tagline-description h3 {
    white-space: nowrap;
    font-size: 1.8rem;
  }
}

@media (max-width: 690px) {

  .Foot footer .foot-naming {
    display: none;
  }

  .Foot footer ul {
    width: 100%;
    justify-content: space-around;
    flex-wrap: nowrap;
  }

  .Foot .nav .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0px;
    height: 45px;
    width: 45px;
    border: none;
    border-radius: 50%;
  }

  .Foot .nav i {
    font-size: 18px;
  }
}

@media (max-width: 570px) {

  .Intro h1 {
    white-space: nowrap;
    font-size: 2.3rem;
  }

  .Intro img {
    height: 220px;
  }
}

@media (max-width: 500px) {
  .Actions form input {
    width: 100px;
  }

  .Intro img {
    height: 180px;
  }

  .AdditionalImages img {
    width: 390px;
    object-fit: contain;
  }


}

@media (max-width: 400px) {
  .Intro h1 {
    white-space: nowrap;
    font-size: 2rem;
  }

  .tagline-description h3 {
    font-size: 1.5rem;
  }

  .Actions form input {
    width: 60px;
  }

  .AdditionalImages img {
    width: 350px;
    object-fit: contain;
  }


}

@media (max-width: 400px) {
  .Intro img {
    height: 150px;
  }
}


.flipbook-container {
  padding: 50px;
  min-height: 90vh;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #00000030;
}

.flipbook {
  background-color: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.view-download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.view-download .btn {
  background-color: black;
  color: white;
  transition: 0.2s ease;
}

.view-download .btn:hover {
  cursor: pointer;
  background-color: white;
  color: black;
  transform: scale(1.1);
}


/* Flipbook pages */
.flipbook-page {
  background-color: #fff;
  padding: 10px;
}

/* Individual PDF Page */
.pdf-page {
  border: 1px solid #ddd;
  border-radius: 5px;
}

/* Page number indicator */
.page-indicator {
  margin-top: 10px;
  text-align: right;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
